/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import useLocales from '@/hooks/useLocales';
import ButtonGroup from '@mui/material/ButtonGroup';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import MultipleStopOutlinedIcon from '@mui/icons-material/MultipleStopOutlined';
import FormatSizeOutlinedIcon from '@mui/icons-material/FormatSizeOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import Button from '@mui/material/Button';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {createFormFields, updateFormFields} from '@/apis/FormFields';
import LoadingScreen from '@/components/common/LoadingScreen';
import {FormControl, FormLabel, TextField} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {toast} from 'react-toastify';

const TextFields = (props: any) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const matches = useMediaQuery('(min-width:1750px)');
  const [label, setLabel] = React.useState('');
  const [type, setType] = React.useState('text');
  const [loading, setLoading] = React.useState(false);
  const [placeholder, setPlaceholder] = React.useState('');

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        submitField();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [label, type, placeholder]);

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      setType(props?.field_type || '');
      setPlaceholder(props?.placeholder || '');
    } else {
      setLabel('');
      setPlaceholder('');
      setType('text');
    }
  }, [props]);

  const submitField = () => {
    if (label.length && placeholder.length && type.length) {
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      return;
    }
    const id = props?.id;
    const load = {
      label,
      field_type: type,
      required: true,
      placeholder
    };
    if (id) {
      updateField({...load, id});
    } else {
      createField(load);
    }
  };

  const createField = async (payload: any) => {
    setLoading(true);
    await createFormFields(payload)
      .then(async () => {
        setLoading(false);
        props?.setUpdated(true);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        handleErrors(error?.response?.data);
      });
  };

  const updateField = async (payload: any) => {
    setLoading(true);
    await updateFormFields(payload)
      .then(async () => {
        props?.setUpdated(true);
        setLoading(false);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        handleErrors(error?.response?.data);
      });
  };

  return (
    <div className="flex flex-col justify-between h-[70vh]">
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('label')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('label')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('placeholder')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={placeholder}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPlaceholder(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('placeholder')}
              error={Boolean(loading && placeholder === '')}
              helperText={
                loading && placeholder === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div className="mb-5 w-100">
          <div className="text-[16px] text-[#212B36]">
            {translate('input_type')}
          </div>
          <p className="mb-3 text-[12px] lh-[24px] w-8/12">
            {translate('sort_info_text')}
          </p>
          <div>
            <ButtonGroup
              disableElevation={true}
              variant="outlined"
              size="small"
              className="group-buttons-container"
              disabled={props?.id}
            >
              <Button
                variant={type === 'text' ? 'contained' : 'outlined'}
                onClick={() => setType('text')}
                startIcon={<FormatSizeOutlinedIcon />}
              >
                {translate('text')}
              </Button>
              <Button
                variant={type === 'email' ? 'contained' : 'outlined'}
                onClick={() => setType('email')}
                startIcon={<EmailOutlinedIcon />}
              >
                {translate('Email')}
              </Button>
              <Button
                variant={type === 'phone_number' ? 'contained' : 'outlined'}
                onClick={() => setType('phone_number')}
                startIcon={<PhoneOutlinedIcon />}
              >
                {translate('phoneNumber')}
              </Button>
              <Button
                variant={type === 'url' ? 'contained' : 'outlined'}
                onClick={() => setType('url')}
                startIcon={<InsertLinkOutlinedIcon />}
              >
                {translate('Link')} (URL)
              </Button>
              {matches && (
                <Button
                  variant={type === 'number' ? 'contained' : 'outlined'}
                  onClick={() => setType('number')}
                  startIcon={<TagOutlinedIcon />}
                >
                  {translate('number')}
                </Button>
              )}
              {matches && (
                <Button
                  variant={type === 'decimal' ? 'contained' : 'outlined'}
                  onClick={() => setType('decimal')}
                  startIcon={<MultipleStopOutlinedIcon />}
                >
                  {translate('Decimal')}
                </Button>
              )}
            </ButtonGroup>
            {!matches && (
              <ButtonGroup
                disableElevation={true}
                variant="outlined"
                size="small"
                className="group-buttons-container mt-1"
                disabled={props?.id}
              >
                <Button
                  variant={type === 'number' ? 'contained' : 'outlined'}
                  onClick={() => setType('number')}
                  startIcon={<TagOutlinedIcon />}
                >
                  {translate('number')}
                </Button>
                <Button
                  variant={type === 'decimal' ? 'contained' : 'outlined'}
                  onClick={() => setType('decimal')}
                  startIcon={<MultipleStopOutlinedIcon />}
                >
                  {translate('Decimal')}
                </Button>
              </ButtonGroup>
            )}
          </div>
        </div>
      </div>
      {loading && validated && <LoadingScreen />}
      <div className="flex">
        <Button
          variant="contained"
          size="small"
          color="inherit"
          className="bg-[#144A6C] mr-5 px-5 text-white tab-right-btn"
          onClick={submitField}
          disabled={!validated && loading}
        >
          {translate('save')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="inherit"
          className="bg-white mr-5 px-5 text-[#144A6C] tab-right-btn"
          onClick={() => {
            setLabel('');
            setType('text');
            setPlaceholder('');
            // setHelpText('');
          }}
        >
          {translate('cancel')}
        </Button>
      </div>
    </div>
  );
};

export default TextFields;
