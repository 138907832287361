/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import InputField from '@/components/common/InputField';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import {FormControl, FormLabel, Grid, TextField} from '@mui/material';
import useLocales from '@/hooks/useLocales';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {createFormFields, updateFormFields} from '@/apis/FormFields';
import LoadingScreen from '@/components/common/LoadingScreen';
import {toast} from 'react-toastify';

const ProjectField = (props: any) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const [label, setLabel] = React.useState('');
  const [helpText, setHelpText] = React.useState('');
  const [type, setType] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [optionList, setOptionList] = React.useState([translate('option')]);

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      if (props?.field_type === 'multiplechoice') {
        setType(true);
      } else {
        setType(false);
      }
      setDescription(props?.description || '');
      setHelpText(props?.help_text || '');
      setOptionList(props?.choices);
    } else {
      setLabel('');
      setDescription('');
      setHelpText('');
      setType(false);
      setOptionList([translate('option')]);
    }
  }, [props]);

  const submitField = () => {
    if (label.length && description.length && optionList.length) {
      setValidated(true);
    } else {
      setValidated(false);
      return;
    }
    const id = props?.id;
    const load = {
      label,
      field_type: type ? 'multiplechoice' : 'choice',
      description,
      help_text: helpText,
      choices: optionList,
      display_type: type ? 'SELECT' : 'CHECKBOX'
    };
    if (id) {
      updateField({...load, id});
    } else {
      createField(load);
    }
  };

  const createField = async (payload: any) => {
    setLoading(true);
    await createFormFields(payload)
      .then(async () => {
        setLoading(false);
        props?.setUpdated(true);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        handleErrors(error?.response?.data);
      });
  };

  const updateField = async (payload: any) => {
    setLoading(true);
    await updateFormFields(payload)
      .then(async () => {
        props?.setUpdated(true);
        setLoading(false);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        handleErrors(error?.response?.data);
      });
  };

  return (
    <div className="flex flex-col justify-between h-[70vh]">
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('title')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('title')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('description')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={description}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('description')}
              error={Boolean(loading && description === '')}
              helperText={
                loading && description === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px]">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('help_text')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={helpText}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setHelpText(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('help_text')}
              error={Boolean(loading && helpText === '')}
              helperText={
                loading && helpText === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div className="my-3">
          <div className="text-[16px] text-[#212B36]">
            {translate('option')}
          </div>
          {optionList.map((option, index) => (
            <Grid
              key={index}
              container={true}
              spacing={3}
              className="w-[300px] items-center"
            >
              <Grid item={true} xs={5}>
                <InputField
                  label={''}
                  placeholder={`${option} ${index + 1}`}
                  type={'text'}
                />
              </Grid>
              <Grid item={true} xs={5}>
                <InputField
                  label={''}
                  placeholder={`${option} ${index + 1}`}
                  type={'text'}
                />
              </Grid>
              <Grid item={true} xs={2} className="pt-1">
                <CloseIcon
                  className="cursor-pointer"
                  onClick={() =>
                    setOptionList(prev => {
                      console.log(prev.length, index);
                      if (prev.length > 1) {
                        prev.splice(index, 1);
                        return [...prev];
                      }
                      return ['Optie'];
                    })
                  }
                />
              </Grid>
            </Grid>
          ))}
          <div className="flex items-center justify-center w-[300px]">
            <AddIcon
              className="text-[#144A6C] cursor-pointer"
              onClick={() => setOptionList(prev => [...prev, 'Optie'])}
            />
          </div>
        </div>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={type}
              onClick={(e: any) => setType(e?.target?.checked)}
            />
          }
          label={translate('multi_select')}
        />
      </div>
      {loading && validated && <LoadingScreen />}
      <div className="flex">
        <Button
          variant="contained"
          size="small"
          color="inherit"
          className="bg-[#144A6C] mr-5 px-5 text-white tab-right-btn"
          onClick={submitField}
          disabled={!validated && loading}
        >
          {translate('save')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="inherit"
          className="bg-white mr-5 px-5 text-[#144A6C] tab-right-btn"
          onClick={() => {
            setLabel('');
            setDescription('');
            setHelpText('');
          }}
        >
          {translate('cancel')}
        </Button>
      </div>
    </div>
  );
  // const payloadProject = {
  //   name: '',
  //   label: '',
  //   description: '',
  //   indicator: '',
  //   type: 'project',
  //   options: [
  //     'Option1',' Option 2'
  //   ]
  // }
};

export default ProjectField;
