/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import axiosInstance from '@/components/utils/axiosInstance';
import type {AxiosError, AxiosResponse} from 'axios';
const token = localStorage.getItem('accessToken');

const base = '/api/notification';
const config = {
  headers: {
    Authorization: `Bearer ${token}`
  }
};
// Get Notifications
export const getNotification = (params?: any) => {
  const accessToken = window.localStorage.getItem('accessToken'); // Retrieve the access token from localStorage

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}` // Add the authorization token to the headers
    },
    params: params || {} // Include any passed query parameters
  };

  return axiosInstance
    .get(`${base}/`, config) // Pass the config object (which contains both headers and params)
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

// Mark Notification as Read
export const markNotificationRead = (payload: any) => {
  return axiosInstance
    .post(`${base}/mark-read/`, payload, config)
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

// Get Unread Notification Count
export const getUnreadCount = () => {
  const accessToken = window.localStorage.getItem('accessToken');

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {}
  };

  return axiosInstance
    .get(`${base}/unread-count/`, config)
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
