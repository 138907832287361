/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import axiosInstance from '@/components/utils/axiosInstance';
import type {AxiosError, AxiosResponse} from 'axios';
const accessToken = localStorage.getItem('accessToken');

const base = '/api/form-field';

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`
  }
};

const configPost = {
  headers: {
    ...config.headers,
    'Content-Type': 'multipart/form-data'
  }
};

export const createFormFields = (payload: any) => {
  return axiosInstance
    .post(`${base}/`, {...payload}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const createImageFields = (payload: any) => {
  return axiosInstance
    .post(`${base}/`, payload, configPost)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getFieldChoices = (params?: any) => {
  return axiosInstance
    .post(
      'api/form-field-choice/filter/',
      {
        ...params
      },
      config
    )
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getFormFields = (query?: any) => {
  return axiosInstance
    .get(`${base}/`, {params: {...query}, ...config})
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const updateFormFields = (payload: any) => {
  return axiosInstance
    .patch(`${base}/${payload.id}/`, {...payload}, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const updateImageFields = (payload: any) => {
  return axiosInstance
    .patch(`${base}/${payload.id}/`, payload.fieldData, configPost)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const deleteFormFields = (userId: string) => {
  return axiosInstance
    .delete(`${base}/${userId}/soft_delete/`, config)
    .then(async (response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
