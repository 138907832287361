/* eslint-disable max-len */
import jwtDecode from 'jwt-decode';
import {verify, sign} from 'jsonwebtoken';
//
import axios from '@/apis/axiosHttp';
import type {AxiosError, AxiosResponse} from 'axios';

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<{exp: number}>(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const clearSession = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('accessTokenExpiration');
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('tokenExpiration');
  window.localStorage.removeItem('refresh');
  window.localStorage.removeItem('refreshExpiration');
  axios.defaults.headers.common.Authorization = undefined;
};

const refreshToken = () => {
  const refresh = window.localStorage.getItem('refresh');
  const payload = window.localStorage.getItem('organization');
  const organization = JSON.parse(payload).id;
  return axios
    .post('/api/auth/token/refresh/', {
      organization,
      refresh
    })
    .then(async (response: AxiosResponse) => {
      const {access, refresh, access_expiration, refresh_expiration} =
        response.data;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('accessTokenExpiration', access_expiration);
      localStorage.setItem('refresh', refresh);
      localStorage.setItem('refreshExpiration', refresh_expiration);
      return access;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const setSession = (
  accessToken: string | null,
  token: string | null = null
) => {
  if (accessToken) {
    const accessTokenExpiration = window.localStorage.getItem(
      'accessTokenExpiration'
    );
    window.localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    if (token) {
      window.localStorage.setItem('token', token);
    }

    if (accessTokenExpiration) {
      const expirationDate = new Date(accessTokenExpiration);
      const currentTime = new Date();
      const timeUntilExpiration =
        expirationDate.getTime() - currentTime.getTime() - 5 * 60 * 1000;

      if (timeUntilExpiration > 0) {
        setTimeout(() => {
          refreshToken();
        }, timeUntilExpiration);
      } else {
        refreshToken();
      }
    }
  } else {
    clearSession();
  }
};

export {isValidToken, verify, sign, setSession, refreshToken, clearSession};
