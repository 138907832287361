/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import useLocales from '@/hooks/useLocales';
import Button from '@mui/material/Button';
import useError from '@/hooks/useError';
import type {AxiosError} from 'axios';
import {createFormFields, updateFormFields} from '@/apis/FormFields';
import LoadingScreen from '@/components/common/LoadingScreen';
import {FormControl, FormLabel, TextField, Grid} from '@mui/material';
import {toast} from 'react-toastify';

const SwitchField = (props: any) => {
  const [validated, setValidated] = React.useState<boolean>(false);
  const {translate} = useLocales();
  const {handleErrors} = useError();
  const [label, setLabel] = React.useState('');
  const [helpText, setHelpText] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [leftLabel, setLeftLabel] = React.useState('');
  const [rightLabel, setRightLabel] = React.useState('');

  React.useEffect(() => {
    if (props?.id) {
      setLabel(props?.label || '');
      setDescription(props?.description || '');
      setHelpText(props?.help_text || '');
    }
  }, [props]);

  const submitField = () => {
    if (
      label.length &&
      description.length &&
      leftLabel.length &&
      rightLabel.length
    ) {
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      return;
    }
    const id = props?.id;
    const load = {
      label,
      field_type: 'boolean',
      description,
      help_text: helpText,
      choices: [leftLabel, rightLabel]
    };
    if (id) {
      updateField({...load, id});
    } else {
      createField(load);
    }
  };

  const createField = async (payload: any) => {
    setLoading(true);
    await createFormFields(payload)
      .then(async () => {
        setLoading(false);
        props?.setUpdated(true);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        handleErrors(error?.response?.data);
      });
  };

  const updateField = async (payload: any) => {
    setLoading(true);
    await updateFormFields(payload)
      .then(async () => {
        props?.setUpdated(true);
        setLoading(false);
        toast.success(translate('submitted_success'));
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        handleErrors(error?.response?.data);
      });
  };

  return (
    <div className="flex flex-col justify-between h-[70vh]">
      <div className="new-textfield-container mt-5">
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('title')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={label}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLabel(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('title')}
              error={Boolean(loading && label === '')}
              helperText={
                loading && label === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl className="w-[300px] mb-5">
            <FormLabel className="text-[#212B36] mb-1">
              {translate('description')}
            </FormLabel>
            <TextField
              fullWidth={true}
              value={description}
              size="small"
              className="bg-white"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
                setLoading(false);
              }}
              placeholder={translate('description')}
              error={Boolean(loading && description === '')}
              helperText={
                loading && description === '' ? translate('required') : null
              }
            />
          </FormControl>
        </div>
        <div className="mb-3 w-[300px]">
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <FormControl className="mb-5">
                <FormLabel className="text-[#212B36] mb-1">
                  {translate('left_label')}
                </FormLabel>
                <TextField
                  fullWidth={true}
                  value={leftLabel}
                  size="small"
                  className="bg-white"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLeftLabel(e.target.value);
                    setLoading(false);
                  }}
                  placeholder={translate('left_label')}
                  error={Boolean(loading && leftLabel === '')}
                  helperText={
                    loading && leftLabel === '' ? translate('required') : null
                  }
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <FormControl className="mb-5">
                <FormLabel className="text-[#212B36] mb-1">
                  {translate('right_label')}
                </FormLabel>
                <TextField
                  fullWidth={true}
                  value={rightLabel}
                  size="small"
                  className="bg-white"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setRightLabel(e.target.value);
                    setLoading(false);
                  }}
                  placeholder={translate('right_label')}
                  error={Boolean(loading && rightLabel === '')}
                  helperText={
                    loading && rightLabel === '' ? translate('required') : null
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>
      {loading && validated && <LoadingScreen />}
      <div className="flex">
        <Button
          variant="contained"
          size="small"
          color="inherit"
          className="bg-[#144A6C] mr-5 px-5 text-white tab-right-btn"
          onClick={submitField}
          disabled={!validated && loading}
        >
          {translate('save')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="inherit"
          className="bg-white mr-5 px-5 text-[#144A6C] tab-right-btn"
          onClick={() => {
            setLabel('');
            setDescription('');
            setHelpText('');
          }}
        >
          {translate('cancel')}
        </Button>
      </div>
    </div>
  );
  // const payloadSwitch = {
  //   name: '',
  //   label: '',
  //   description: '',
  //   type: 'switch',
  // }
};

export default SwitchField;
