export const checkRole = (allowedRoles: string[], category: string | null) => {
  const userPermissions = JSON.parse(localStorage.getItem('permissions'));

  // check for owner and return true
  if (userPermissions === null || category === null) {
    return true;
  }

  // check for deleted permissions
  if (userPermissions === undefined) {
    return false;
  }

  // no instance of permission
  if (userPermissions[category] === undefined) {
    return false;
  }

  // no specified roles
  if (allowedRoles.length === 0) {
    return true;
  }

  const userRoles: string[] = [];

  userPermissions[category]?.forEach((role: string) => {
    userRoles.push(role.toLowerCase());
  });

  if (userRoles.length === 0) {
    return false;
  }

  return userRoles.some(role => allowedRoles.includes(role));
};
