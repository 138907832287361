/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {type ReactNode} from 'react';
import {Container, Alert, AlertTitle} from '@mui/material';
import {checkRole} from '@/components/utils/roles';
import useLocales from '@/hooks/useLocales';
// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

export default function RoleBasedGuard({
  accessibleRoles,
  children
}: RoleBasedGuardProp) {
  const {translate} = useLocales();
  if (!checkRole(accessibleRoles)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>{translate('permission_denied')}</AlertTitle>
          {translate('permission_denied_sub')}
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
