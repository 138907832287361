/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {
  createContext,
  type ReactNode,
  useEffect,
  useReducer
} from 'react';
import axiosHttp from '@/apis/axiosHttp';
import {isValidToken, setSession} from '@/components/utils/jwt';
import type {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType
} from '@/@types/authentication';
import {Types} from '@/@types/enum';
import type {AxiosResponse, AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {getNotification} from '@/apis/Notifications';

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({children}: {children: ReactNode}) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          await axiosHttp
            .post('/api/auth/token/verify/', {
              token: accessToken
            })
            .then(async (response: AxiosResponse) => {
              const user = response.data;
              if (response.status === 401) {
                toast.info('Session expired!');
                logout();
              }
              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: true,
                  user
                }
              });
            })
            .catch((error: AxiosError) => {
              logout();
              return error;
            });
        } else {
          logout();
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setInterval(() => {
          getNotification()
            .then(() => {
              return;
            })
            .catch((error: AxiosError) => {
              return Promise.reject(error);
            });
        }, 60 * 1000);
      }
    };

    initialize();
  }, []);

  const getDomain = (domain: string) => {
    return axiosHttp
      .get('/api/domain/check_domain', {
        params: {
          domain: domain
        }
      })
      .then(async (response: AxiosResponse) => {
        const domain = response.data;
        if (domain.detail === 'ok') {
          return response;
        }
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const login = (email: string, password: string) => {
    return axiosHttp
      .post('/api/auth/token/', {
        email,
        password
      })
      .then(async (response: AxiosResponse) => {
        const {access} = response.data;
        setSession(access);
        const {data} = await axiosHttp.get('/api/auth/user/');
        localStorage.setItem(
          'accessTokenExpiration',
          response.data.access_expiration
        );
        localStorage.setItem('refresh', response.data.refresh);
        localStorage.setItem(
          'refreshExpiration',
          response.data.refresh_expiration
        );
        if (!data) {
          logout();
        }
        window.localStorage.setItem('user', JSON.stringify(data));
        const organizations = await axiosHttp.post(
          '/api/organization/filter/',
          {
            filters: {
              operation: 'AND',
              predicates: []
            },
            pagination: {
              page: 1,
              page_size: 10
            }
          }
        );
        if (organizations.data) {
          localStorage.setItem(
            'organizations',
            JSON.stringify(organizations.data.results)
          );
        }
        dispatch({
          type: Types.Login,
          payload: {
            user: data
          }
        });
        return response;
      })
      .catch((error: AxiosError) => {
        const url = window.location.href;
        const match = /auth\/login/.exec(url);
        if (match) {
          console.log(match[1]);
        }
        return Promise.reject(error);
      });
  };

  const refreshToken = () => {
    const refresh = window.localStorage.getItem('refresh');
    const payload = window.localStorage.getItem('organization');
    const organization = JSON.parse(payload).id;
    return axiosHttp
      .post('/api/auth/token/refresh/', {
        organization,
        refresh
      })
      .then(async (response: AxiosResponse) => {
        const {access, refresh, access_expiration, refresh_expiration} =
          response.data;
        setSession(access);
        localStorage.setItem('accessToken', access);
        localStorage.setItem('accessTokenExpiration', access_expiration);
        localStorage.setItem('refresh', refresh);
        localStorage.setItem('refreshExpiration', refresh_expiration);
        return access;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const register = async (
    first_name: string,
    last_name: string,
    password1: string,
    password2: string,
    email?: string,
    invite_token?: string
  ) => {
    const payload = {
      first_name,
      last_name,
      password1,
      password2,
      email,
      invite_token
    };

    // Remove 'invite_token' or 'email' if it's empty
    if (!payload.email) {
      payload.email = undefined;
    }

    if (!payload.invite_token) {
      payload.invite_token = undefined;
    }

    return axiosHttp
      .post('/api/registration/', payload)
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const emailResend = async (email: string) => {
    return axiosHttp
      .post('/api/auth/email/resend/', {
        email
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const resetDomainEmail = (email: string) => {
    return axiosHttp
      .post('/api/domain/forgot_domain/', {
        email
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const logout = () => {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
    setSession(null);
    const lang = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('i18nextLng', lang);
    dispatch({type: Types.Logout});
  };

  const forgotPassword = (email: string) => {
    return axiosHttp
      .post('/api/auth/password/reset/', {
        email
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const getSubscription = (token: string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return axiosHttp
      .get('/api/subscriptions/', config)
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const verifyEmail = (key: string) => {
    return axiosHttp
      .post('/api/auth/email/verify/', {
        key
      })
      .then(async (response: AxiosResponse) => {
        const {access, refresh, access_expiration, refresh_expiration} =
          response.data;
        setSession(access);
        const {data} = await axiosHttp.get('/api/auth/user/');
        localStorage.setItem('accessTokenExpiration', access_expiration);
        localStorage.setItem('refresh', refresh);
        localStorage.setItem('refreshExpiration', refresh_expiration);
        window.localStorage.setItem('user', JSON.stringify(data));
        const organizations = await axiosHttp.post('/api/organization/filter/');
        localStorage.setItem(
          'organizations',
          JSON.stringify(organizations.data.results)
        );
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const resetPassword = (newPassword: string, passwordConfirm: string) => {
    return axiosHttp
      .post('/api/auth/change/', {
        new_password1: newPassword,
        new_password2: passwordConfirm
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const resetEmailPassword = (
    newPassword: string,
    confirmNewPassowrd: string,
    uidb64: string,
    token: string
  ) => {
    return axiosHttp
      .post('/api/auth/password/reset/confirm/', {
        new_password1: newPassword,
        new_password2: confirmNewPassowrd,
        uid: uidb64,
        token: token
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const setProfile = (
    tenant: string,
    email: string,
    firstName: string,
    lastName: string,
    role: string
  ) => {
    return axiosHttp
      .post('/api/users', {
        email: email,
        first_name: firstName,
        last_name: lastName,
        role: role
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const setOrganization = async (
    industry: string,
    companyName: string,
    employeeNumber: number,
    token: string
  ) => {
    const payload = {
      name: companyName,
      industry: industry,
      size: employeeNumber
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    return axiosHttp
      .post('/api/organization/', {...payload}, config)
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const updateProfile = (userDetails: unknown) => {
    return axiosHttp
      .patch('/api/auth/user/', {
        userDetails
      })
      .then(async (response: AxiosResponse) => {
        const {data} = response;
        if (!data) {
          logout();
        }
        window.localStorage.setItem('user', JSON.stringify(data));
        dispatch({
          type: Types.Login,
          payload: {
            user: data
          }
        });
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const getUser = (token: string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return axiosHttp
      .get('/api/auth/user/', config)
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        getDomain,
        getUser,
        logout,
        register,
        resetPassword,
        setOrganization,
        resetEmailPassword,
        getSubscription,
        emailResend,
        forgotPassword,
        resetDomainEmail,
        verifyEmail,
        updateProfile,
        setProfile,
        dispatch,
        refreshToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export {AuthContext, AuthProvider};
