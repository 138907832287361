/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import {PATH_AUTH} from '@/router/paths';
import React, {type ReactNode, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({children}: AuthGuardProps) {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  const user = window.localStorage.getItem('user');

  useEffect(() => {
    ensureAuth();
  }, []);

  const ensureAuth = () => {
    if (!user) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }

      return navigate(PATH_AUTH.login);
    }
    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null);
      return navigate(requestedLocation);
    }
  };
  return <>{children}</>;
}
