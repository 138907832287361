export type Role = {
  id: number;
  code_name: string;
};

export const checkRole = (allowedRoles: string[]) => {
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const userRoles: string[] = [];
  loggedInUser?.roles?.forEach((role: Role) => {
    userRoles.push(role.code_name);
  });

  if (allowedRoles.length === 0) {
    return true;
  }

  if (userRoles.length === 0) {
    return false;
  }

  return userRoles.some(role => allowedRoles.includes(role));
};
