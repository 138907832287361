/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {Suspense, lazy} from 'react';
import {useRoutes, BrowserRouter, Navigate} from 'react-router-dom';
import DesktopLayout from '@/layouts/DesktopLayout';
import LogoOnlyLayout from '@/layouts/LogoOnlyLayout';
import GuestGuard from '@/guards/GuestGuard';
import AuthGuard from '@/guards/AuthGuard';
import LoadingScreen from '@/components/common/LoadingScreen';
import RoleBasedGuard from '@/guards/RoleGuard';
import {PATH_AUTH, PATH_DASHBOARD, PATH_PAGE} from './paths';
import {SettingsProvider} from '@/views/desktop/settings/store';
// ----------------------------------------------------------------------

const loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const AppRouting = () => {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.registerEmailSent,
          element: (
            <GuestGuard>
              <RegisterEmailSent />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.registerEmailVerify,
          element: (
            <GuestGuard>
              <RegisterEmailVerified />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.companyRegisterForm,
          element: (
            <GuestGuard>
              <RegisterCompany />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.forgotPasswordEmail,
          element: (
            <GuestGuard>
              <ForgotPasswordEmail />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.forgotPasswordEmailSent,
          element: (
            <GuestGuard>
              <ForgotPasswordEmailSent />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.resetPassword,
          element: <ResetPassword />
        }
      ]
    },
    {
      path: PATH_AUTH.publicRoot,
      children: [
        {
          path: PATH_AUTH.forgotDomainEmail,
          element: (
            <GuestGuard>
              <ForgotDomainEmail />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.forgotDomainEmailSent,
          element: (
            <GuestGuard>
              <ForgotDomainEmailSent />
            </GuestGuard>
          )
        },
        {
          path: PATH_AUTH.registerForm,
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        }
      ]
    },
    {path: '/', element: <Navigate to={PATH_DASHBOARD.root} replace={true} />},
    {
      path: PATH_DASHBOARD.root,
      children: [
        {
          path: PATH_DASHBOARD.root,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Dashboard />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.planboard,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Planboard />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.tasks,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Tasks />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.reports,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Reports />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.relations,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Relations />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.client_details,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <ClientDetails />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.meetings,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Meetings />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.info,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Info />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.settings,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Settings />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.department_details,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Department />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.user_details,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <SettingsUserDetails />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.settings_newfield,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <SettingsNewField />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.settings_newform,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <SettingsNewForm />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.settings_admin,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <SettingsAdmin />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.settings_step_templates,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <SettingsStepTemplates />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.plugins,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Plugins />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.facilities,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Facilities />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.project,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <DesktopLayout>
                    <Project />
                  </DesktopLayout>
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        },
        {
          path: PATH_DASHBOARD.organizations,
          element: (
            <AuthGuard>
              <SettingsProvider>
                <RoleBasedGuard accessibleRoles={[]}>
                  <Organisation />
                </RoleBasedGuard>
              </SettingsProvider>
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: PATH_PAGE.page404,
      element: (
        <LogoOnlyLayout>
          <NotFound />
        </LogoOnlyLayout>
      )
    }
  ]);
};

export default function Router() {
  return (
    <BrowserRouter>
      <AppRouting />
    </BrowserRouter>
  );
}

const Login = loadable(lazy(() => import('@/pages/auth/Login')));
// const Domain = loadable(lazy(() => import('@/pages/auth/Domain')));
const Register = loadable(lazy(() => import('@/pages/auth/Register')));
const RegisterCompany = loadable(
  lazy(() => import('@/pages/auth/RegisterCompany'))
);
const ForgotPasswordEmail = loadable(
  lazy(() => import('@/pages/auth/ForgotPasswordEmail'))
);
const NotFound = loadable(lazy(() => import('@/components/common/NotFound')));
const ResetPassword = loadable(
  lazy(() => import('@/pages/auth/ResetPassword'))
);
const ForgotPasswordEmailSent = loadable(
  lazy(() => import('@/pages/auth/ForgotPasswordEmailSent'))
);
const RegisterEmailSent = loadable(
  lazy(() => import('@/pages/auth/RegisterEmailSent'))
);
const RegisterEmailVerified = loadable(
  lazy(() => import('@/pages/auth/RegisterEmailVerified'))
);
const ForgotDomainEmail = loadable(
  lazy(() => import('@/pages/auth/ForgotDomainEmail'))
);
const ForgotDomainEmailSent = loadable(
  lazy(() => import('@/pages/auth/ForgotDomainEmailSent'))
);
const Dashboard = loadable(lazy(() => import('@/pages/dashboard')));
const Organisation = loadable(lazy(() => import('@/pages/auth/Organisation')));
const Department = loadable(
  lazy(() => import('@/pages/dashboard/Settings/SettingsDepartment'))
);
const Facilities = loadable(lazy(() => import('@/pages/dashboard/Facilities')));
const Reports = loadable(lazy(() => import('@/pages/dashboard/Reports')));
const Relations = loadable(lazy(() => import('@/pages/dashboard/Relations')));
const ClientDetails = loadable(
  lazy(() => import('@/pages/dashboard/Clients/ClientsDetails'))
);
const Tasks = loadable(lazy(() => import('@/pages/dashboard/Tasks')));
const Meetings = loadable(lazy(() => import('@/pages/dashboard/Meetings')));
const Info = loadable(lazy(() => import('@/pages/dashboard/Info')));
const Planboard = loadable(lazy(() => import('@/pages/dashboard/Planboard')));
const SettingsUserDetails = loadable(
  lazy(() => import('@/pages/dashboard/Settings/SettingsUserDetails'))
);
const Settings = loadable(lazy(() => import('@/pages/dashboard/Settings')));
const SettingsNewField = loadable(
  lazy(() => import('@/pages/dashboard/Settings/SettingsNewFields'))
);
const SettingsNewForm = loadable(
  lazy(() => import('@/pages/dashboard/Settings/SettingsNewForm'))
);
const SettingsAdmin = loadable(
  lazy(() => import('@/pages/dashboard/Settings/SettingsAdmin'))
);
const SettingsStepTemplates = loadable(
  lazy(() => import('@/pages/dashboard/Settings/SettingsStepTemplates'))
);
const Plugins = loadable(lazy(() => import('@/pages/dashboard/Plugins')));
const Project = loadable(lazy(() => import('@/pages/dashboard/Project')));
