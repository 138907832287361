/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import axiosHttp from '@/apis/axiosHttp';
import type {AxiosError, AxiosResponse} from 'axios';

const base = '/api/notification';
const accessToken = localStorage.getItem('accessToken');
const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`
  }
};

export const getNotification = (params?: any) => {
  return axiosHttp
    .get(`${base}/`, {params: {...params}, ...config})
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const markNotificationRead = (payload: any) => {
  return axiosHttp
    .post(`${base}/mark-read/`, {...payload}, {...config})
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export const getUnreadCount = () => {
  return axiosHttp
    .get(`${base}/unread-count/`, {params: {}, ...config})
    .then(async (response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
