/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import meinLogo2 from '@/assets/img/logo_mienplatform.png';
import meinLogo from '@/assets/img/logo.png';
import {classNames} from '@/components/utils/helper';
import {checkRole} from '@/components/utils/roles';
import useLocales from '@/hooks/useLocales';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import AdminIcon from '../../../assets/img/icons/admin_settings.svg';
import buildingIcon from '../../../assets/img/icons/building.svg';
import calendarIcon from '../../../assets/img/icons/calendar.svg';
import relationsIcon from '../../../assets/img/icons/relations.svg';
import settingsIcon from '../../../assets/img/icons/settingsNew.svg';
import AccountInline from './AccountInline';
import LanguageSwitcher from '../LanguageSwitcher';
import {PowerIcon} from '@heroicons/react/20/solid';
import useAuth from '@/hooks/useAuth';

type DesktopSideNavProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DesktopSideNav: React.FC<DesktopSideNavProps> = ({
  open,
  setOpen
}: DesktopSideNavProps) => {
  const location = useLocation();
  const {translate} = useLocales();
  const {logout} = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const handleClick = (bool: boolean) => {
    setOpen(bool);
  };

  const secondaryNavigation = [
    {
      name: `${translate('desktop')}`,
      icon: buildingIcon,
      href: '/dashboard',
      hasMore: false
    },
    {
      name: `${translate('planboard')}`,
      icon: calendarIcon,
      href: '/dashboard/planboard',
      hasMore: false
      // allowed: [],
    },
    {
      name: `${translate('clients')}`,
      icon: relationsIcon,
      href: '/dashboard/relations',
      hasMore: false
      // allowed: [],
    },
    {
      name: `${translate('settings')}`,
      icon: settingsIcon,
      href: '/dashboard/settings',
      hasMore: false
      // allowed: [],
    }
  ];

  return (
    <div className="flex h-screen flex-1 flex-col bg-hak4-bg-light-blue">
      <nav
        className="flex-1 space-y-2 bg-hak4-bg-light-blue"
        aria-label="Sidebar"
      >
        <div
          className={classNames(
            open ? 'flex-col text-center' : 'flex-row justify-between',
            'pt-3 pl-2 md:flex'
          )}
        >
          <span className="block text-center">
            <PowerIcon
              className="h-6 w-6 cursor-pointer mx-auto text-gray-400"
              onClick={() => {
                logout();
                window.location.reload();
              }}
            />
          </span>
          <span>
            {!open ? (
              <ChevronLeft
                className="h-8 w-8 cursor-pointer text-gray-400"
                onClick={() => handleClick(true)}
              />
            ) : (
              <ChevronRight
                className="h-8 w-8 cursor-pointer text-gray-400"
                onClick={() => handleClick(false)}
              />
            )}
          </span>
        </div>
        <div className={`flex justify-center py-4 ${!open && 'mx-5'}`}>
          <img src={meinLogo} alt="" width={!open ? '60%' : '70%'} />
        </div>
        <AccountInline open={!open} />
        <div
          className={classNames(
            !open ? 'xl:h-[43vh]' : 'xl:h-[48vh]',
            '2xl:[60vh] scrollableMenu noScrollbar overflow-y-scroll'
          )}
        >
          <div className="space-y-2 nav-tab-container" role="group">
            {secondaryNavigation
              .filter(() => checkRole([]))
              .map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    open ? 'justify-center pl-2' : ' pl-6',
                    location.pathname === item.href
                      ? 'hak4t-link-light-blue'
                      : 'hover:bg-hak4-hover-bg',
                    'flex items-center py-2 text-sm rounded-md w-full'
                  )}
                >
                  <item.icon
                    className={classNames(
                      open
                        ? 'h-7 w-7 navbar-icon-opened'
                        : 'h-6 w-6 navbar-icon',
                      'mr-3 navbar-icon flex-shrink-0'
                    )}
                    aria-hidden="true"
                  />
                  <span
                    style={{
                      opacity: !open ? 1 : 0,
                      transform: !open ? 'translateX(0)' : 'translateX(-1rem)',
                      transition:
                        'opacity 1000ms, transform 1000ms, max-width 1000ms',
                      maxWidth: !open ? '200px' : '0',
                      overflow: 'hidden',
                      display: !open ? 'block' : 'hidden'
                    }}
                    className="flex-1 hak4t-text-dark"
                  >
                    {!open && item.name}
                  </span>

                  <div>
                    {item.hasMore ? (
                      <ChevronRight className="h-5 w-5 cursor-pointer text-gray-400" />
                    ) : (
                      ''
                    )}
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </nav>
      {user?.role !== 'owner' && (
        <div className="mx-1">
          <Link
            to={'/dashboard/settings/admin'}
            className={classNames(
              open ? 'justify-center pl-2' : ' pl-3',
              'flex items-center py-1 text-sm rounded-md w-full border border-1 border-gray-300'
            )}
          >
            <AdminIcon
              className={classNames(
                open ? 'h-7 w-7' : 'h-6 w-6',
                'mr-2 hak4t-icon-light-blue flex-shrink-0'
              )}
              aria-hidden="true"
            />
            <span
              style={{
                opacity: !open ? 1 : 0,
                transform: !open ? 'translateX(0)' : 'translateX(-1rem)',
                transition:
                  'opacity 1000ms, transform 1000ms, max-width 1000ms',
                maxWidth: !open ? '100%' : '0',
                overflow: 'hidden',
                display: !open ? 'block' : 'hidden'
              }}
              className="flex-1 hak4t-text-dark"
            >
              {!open && translate('admin_mode')}
            </span>
          </Link>
        </div>
      )}
      <div className={open ? 'md:w-12 pt-1 mx-auto' : 'md:w-50 pt-1 mx-auto'}>
        <LanguageSwitcher open={true} showText={!open} />
      </div>
      <div className="flex flex-shrink-0 px-4 py-1 bg-hak4-bg-light-blue">
        <div className="group block w-full flex-shrink-0">
          <div className="flex items-center">
            <div
              className={classNames(
                !open
                  ? 'flex items-center justify-between hideItem'
                  : 'text-center mb-1'
              )}
            >
              <div className="mr-1">
                <a className="text-xxs text-gray-700 group-hover:text-gray-900 leading-none">
                  {translate('powered_by')}
                </a>
              </div>
              <img
                className="block ml-1"
                src={meinLogo2}
                alt=""
                width={open ? '100%' : '60%'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopSideNav;
